import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import { navigate } from "gatsby-link";
import PageWrapper from "../components/PageWrapper";
import queryString from "query-string";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_TIEBREAKER_TEMPLATE = gql`
  query GetTiebreakerTemplate($id: ID!) {
    tiebreakerTemplate: getTiebreakerTemplate(id: $id) {
      id
      text
      answer
      answerValue
      status
    }
  }
`;

const ADD_TIEBREAKER_TO_QUIZ_FROM_TEMPLATE = gql`
  mutation AddTiebreakerToQuizFromTemplate(
    $quizId: ID!
    $tiebreakerTemplateId: ID!
  ) {
    addTiebreakerToQuizFromTemplate(
      quizId: $quizId
      tiebreakerTemplateId: $tiebreakerTemplateId
    ) {
      quiz {
        id
        name
        publicId
      }
    }
  }
`;

const QuestionTemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  font-size: 20px;
`;

const QuestionDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

const AnswerLabel = styled.span`
  color: rgb(153, 153, 153);
`;

const Answer = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export default function QuizList() {
  const { quizId, tiebreakerTemplateId, eventId } = queryString.parse(
    location.search
  );

  const { data } = useQuery(GET_TIEBREAKER_TEMPLATE, {
    variables: {
      id: tiebreakerTemplateId,
    },
  });

  const [addTiebreakerToQuizFromTemplate] = useMutation(
    ADD_TIEBREAKER_TO_QUIZ_FROM_TEMPLATE
  );

  if (!data) {
    return null;
  }

  const tiebreakerTemplate = data.tiebreakerTemplate;

  const handleAddToQuiz = async () => {
    await addTiebreakerToQuizFromTemplate({
      variables: {
        quizId,
        tiebreakerTemplateId,
      },
    });
    if (eventId) {
      await navigate(`/create-edit-event?eventId=${eventId}`);
    } else {
      await navigate(`/create-edit-quiz?quizId=${quizId}`);
    }
  };

  const handleCancel = async () => {
    await navigate(
      `/select-tiebreaker-template?quizId=${quizId}&eventId=${eventId}`
    );
  };

  return (
    <PageWrapper>
      <DarkPageHeading>Tiebreaker</DarkPageHeading>
      <QuestionTemplatesContainer>
        <QuestionDetails>
          <Text>{tiebreakerTemplate.text}</Text>
          <Answer>
            <AnswerLabel>Answer: </AnswerLabel>
            {tiebreakerTemplate.answer}
          </Answer>
        </QuestionDetails>
      </QuestionTemplatesContainer>
      <ActionBar>
        <Button size="large" onClick={handleAddToQuiz}>
          Add to {eventId ? "Event" : "Quiz"}
        </Button>
        <Button size="large" onClick={handleCancel}>
          Cancel
        </Button>
      </ActionBar>
    </PageWrapper>
  );
}
